<template>
  <el-tooltip
    class="item"
    effect="dark"
    content="Inviter utilisateur"
    placement="top"
  >
    <el-button type="primary" :icon="PlusIcon" plain @click="handleOpen"
      >Inviter</el-button
    >
  </el-tooltip>
  <el-dialog
    title="Ajout d'un utilisateur "
    v-model="dialogFormAdd"
    append-to-body
    center
  >
    <el-form ref="inviteForm" :model="form" :rules="rules">
      <el-form-item label="Email " :label-width="formLabelWidth" prop="email">
        <el-autocomplete
          v-model="form.email"
          :fetch-suggestions="querySearch"
          clearable
          class="inline-input"
          style="width: 60%"
          placeholder="Saisir un email"
          @select="handleSelect"
          @input="handleInput"
        />
        <span v-if="!emailExist && this.form.email">
          <AddUser :email="form.email" />
        </span>
      </el-form-item>
      <el-form-item label="Rôle " :label-width="formLabelWidth" prop="appRole">
        <el-select
          v-model="form.appRole"
          class="m-2"
          placeholder="Rôle"
          clearable
        >
          <el-option
            v-for="item in filtredRoles"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        v-if="isAppAdmin && isRetailerRole"
        label="Revendeur "
        :label-width="formLabelWidth"
        prop="retailerId"
      >
        <el-select
          v-model="form.retailerId"
          placeholder="Revendeur"
          clearable
          filterable
          :loading="loading"
        >
          <el-option
            v-for="item in retailerOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormAdd = false" type="danger" plain
          >Annuler</el-button
        >
        <el-button
          type="primary"
          @click="Invite('inviteForm')"
          :disabled="!emailExist"
          >Inviter</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import AddUser from './AddUser.vue'
export default {
  components: { AddUser },
  data() {
    return {
      dialogFormAdd: false,
      formLabelWidth: '120px',
      selectedUser: null,
      emailOptions: [],
      retailerOptions: [],
      loading: false,
      emailExist: false,
      isRetailerRole: false,
      form: {
        email: null,
        appRole: null,
        retailerId: null,
      },
      confirmPassword: null,
      rules: {
        email: [
          {
            required: true,
            type: 'email',
            message: "Veuillez saisir l'adresse e-mail correcte",
            trigger: 'change',
          },
        ],
        appRole: [
          {
            required: false,
            message: 'Veuillez saisir le role',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  watch: {
    'form.appRole': {
      handler(newVal) {
        this.isRetailerRole = ['RETAILER_ADMIN', 'RETAILER_MANAGER'].includes(
          newVal
        )
        this.getRetailers()
      },
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      isAppAdmin: 'auth/isAppAdmin',
      retailers: 'retailers/retailers',
      roleOptions: 'auth/roleOptions',
    }),
    filtredRoles() {
      let role = this.user?.appRole ?? null
      let selectedIndex = this.roleOptions.findIndex(
        (item) => item.value == role
      )
      return this.roleOptions.filter((item, index) => index >= selectedIndex)
    },
  },
  mounted() {},
  methods: {
    querySearch(queryString, cb) {
      this.$store
        .dispatch('users/getUsersByQuery', { params: { q: queryString } })
        .then((data) => {
          this.filtredUsers = data
          let result = data.map((item) => ({
            ...item,
            value: item.email,
          }))
          cb(result)
        })
    },
    handleOpen() {
      this.dialogFormAdd = true
      this.selectedUser = null
      this.form = {
        email: null,
        appRole: null,
      }
    },
    handleSelect(item) {
      this.selectedUser = item
      this.emailExist = true
    },
    handleInput() {
      if (this.filtredUsers && this.filtredUsers.length == 0) return
      let index = this.filtredUsers.findIndex(
        (element) => element.email == this.form.email
      )
      if (index !== -1) {
        this.emailExist = true
      } else {
        this.emailExist = false
      }
    },
    getEmails(query) {
      if (query) {
        this.loading = true
        this.$store
          .dispatch('users/getUsersByQuery', { params: { q: query } })
          .then((data) => {
            this.filtredUsers = data
            this.loading = false
            this.emailOptions = data
          })
          .catch(() => (this.filtredUsers = []))
      } else {
        this.emailOptions = []
      }
    },
    Invite(inviteForm) {
      this.$refs[inviteForm].validate((valid) => {
        if (valid && this.selectedUser) {
          let payload = {
            id: this.selectedUser.id,
            data: {
              ...this.form,
            },
          }
          this.$store.dispatch('users/updateUser', payload).then((response) => {
            this.dialogFormAdd = false
            if (response.data.type == 'membership') {
              this.$notify({
                title: 'Warning',
                message: 'user already a member',
                type: 'warning',
                offset: 100,
              })
            }
          })
        } else return false
      })
    },
    getRetailers() {
      if (!this.isAppAdmin || !this.isRetailerRole) return
      this.loading = true
      if (this.retailers.length > 0) {
        this.retailerOptions = this.retailers
        this.loading = false
        return
      }
      this.$store
        .dispatch('retailers/getRetailers', {})
        .then(() => {
          this.retailerOptions = this.retailers
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
