<template>
  <el-container direction="vertical">
    <el-row justify="space-between">
      <el-col :span="4"
        >
        <div class="user-title">
        <span>Utilisateurs </span
        ><span class="user-count" v-if="totalElements > 0">{{
          totalElements
        }}</span>
        
      </div>
        
        </el-col
      >
      <el-col :span="6">
        <el-space alignment="flex-end">
          <el-input
            v-model="search"
            :prefix-icon="SearchIcon"
            placeholder="Taper pour chercher"
            @keyup.enter="handleSearch"
          />
          <InviteUser />
        </el-space>
      </el-col>
    </el-row>

    <el-table stripe :data="users">
      <el-table-column label="Name" prop="fullname"> </el-table-column>
      <el-table-column label="Email" prop="email"> </el-table-column>
      <el-table-column label="Téléphone" prop="phone"> </el-table-column>
      <el-table-column label="Rôle" prop="appRole">
        <template #default="scope"
          >{{ formatRole(scope.row.appRole) }}
        </template>
      </el-table-column>
      <el-table-column v-if="isAppUser" label="Revendeur" prop="retailer">
        <template #default="scope"
          >{{ formatRetailer(scope.row.retailer) }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="Action" width="120">
        <template #default="scope"> <EditUser :row="scope.row" /> </template>
      </el-table-column>
      <el-table-column fixed="right" label="Etat" width="80">
        <template #default="scope" align="right">
          <el-space>
            <el-tooltip placement="left">
              <template #content> Changer état</template>
              <el-switch
                v-model="scope.row.enabled"
                @change="enableUser(scope.row)"
              />
            </el-tooltip>
          </el-space>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" class="row-bg" justify="center" v-if="metadata">
      <el-pagination
        :current-page="page"
        layout="total, prev, pager, next, jumper"
        :total="metadata.totalElements"
        :page-size="20"
        @current-change="setPage"
      >
      </el-pagination>
    </el-row>
  </el-container>
</template>

<script>
import InviteUser from './InviteUser.vue'
import EditUser from './EditUser.vue'
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'
export default {
  components: { InviteUser, EditUser },
  data() {
    return {
      search: '',
      searchInvi: '',
      dialogFormVisible: false,
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
      },
      formLabelWidth: '150px',
      optionWidth: '100px',
    }
  },
  computed: {
    ...mapGetters({
      isadmin: 'auth/isadmin',
      user: 'auth/user',
      index: 'users/index',
      users: 'users/users',
      usertoEdit: 'users/user',
      isAppUser: 'auth/isAppUser',
      roleOptions: 'auth/roleOptions',
      organisation: 'organisations/organisation',
      metadata: 'users/metadata',
    }),
    page() {
      return Number(_.get(this.metadata, 'pageable.pageNumber', 0)) + 1
    },
    totalElements() {
      return _.get(this.metadata, 'totalElements', '0')
    },
    filtredRoles() {
      let role = this?.user?.appRole ?? null
      let selectedIndex = this.roleOptions.findIndex(
        (item) => item.value == role
      )
      return this.roleOptions.filter((item, index) => index >= selectedIndex)
    },
  },
  mounted() {},
  methods: {
    ...mapActions({
      setUser: 'users/setUser',
      setIndex: 'users/setIndex',
      deleteUser: 'users/deleteUser',
    }),
    formatRole(appRole) {
      let obj = _.find(this.roleOptions, { value: appRole })
      if (obj) return obj.label
    },
    formatRetailer(retailer) {
      if (retailer) return retailer.name
    },
    handleSearch() {
      let payload = {
        params: {
          q: this.search,
        },
      }
      this.$store.dispatch('users/getUsers', payload)
    },
    enableUser(row) {
      let payload = {
        id: row.id,
        data: {
          enabled: row.enabled,
        },
      }
      this.$store.dispatch('users/updateUser', payload)
    },
    setPage(val) {
      let payload = {
        params: {
          page: val - 1,
        },
      }
      this.$store.dispatch('users/getUsers', payload)
    },
  },
}
</script>

<style>
.cell {
  display: flex;
  align-items: center;
}
.user-avatar {
  background-color: #042055;
}
form label {
  font-weight: bold;
}

.user-title {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}

.user-title span:first-child {
  margin-right: 5px;
}

.user-count {
  font-size: 16px;
  font-weight: normal;
  margin-left: 5px;
}

</style>
