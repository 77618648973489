<template>
  <el-tooltip
    class="item"
    effect="dark"
    content="Inviter utilisateur"
    placement="top"
  >
    <el-button type="text" plain @click="handleOpen"
      >l'email n'existe pas, cliquez ici pour l'ajouter</el-button
    >
  </el-tooltip>
  <el-dialog
    title="Ajout d'un utilisateur "
    v-model="dialogFormAdd"
    append-to-body
    center
  >
    <el-form ref="userForm" :model="form" :rules="rules">
      <el-form-item label="Email " :label-width="formLabelWidth" prop="email">
        <el-input v-model="form.email" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item
        label="Prénom "
        :label-width="formLabelWidth"
        prop="firstname"
      >
        <el-input v-model="form.firstname" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Nom " :label-width="formLabelWidth" prop="lastname">
        <el-input v-model="form.lastname" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Email " :label-width="formLabelWidth" prop="email">
        <el-select
          v-model="form.email"
          filterable
          remote
          clearable
          placeholder="Chercher un email"
          :remote-method="getEmails"
          :loading="loading"
        >
          <el-option
            v-for="item in emailOptions"
            :key="item.id"
            :label="item.email"
            :value="item.email"
          >
          </el-option>
        </el-select>
        <span v-if="emailExist" class="text-danger small ml-2"
          >Email existe déja !</span
        >
      </el-form-item>
      <el-form-item
        label="Mot de passe"
        :label-width="formLabelWidth"
        prop="password"
      >
        <el-input
          v-model="form.password"
          autocomplete="off"
          show-password
        ></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormAdd = false" type="danger" plain
          >Annuler</el-button
        >
        <el-button
          type="primary"
          @click="Invite('userForm')"
          :disabled="emailExist"
          >Inviter</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    email: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialogFormAdd: false,
      formLabelWidth: '150px',
      emailOptions: [],
      loading: false,
      form: {
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        password: null,
      },
      confirmPassword: null,
      rules: {
        firstname: [
          {
            required: true,
            message: 'Veuillez saisir le prénom',
            trigger: 'change',
          },
        ],
        lastname: [
          {
            required: true,
            message: 'Veuillez saisir le nom',
            trigger: 'change',
          },
        ],
        email: [
          {
            required: true,
            message: "Veuillez saisir l'email",
            trigger: 'change',
          },
        ],
        password: [
          {
            required: true,
            message: 'Veuillez saisir le mot de passe',
            trigger: 'change',
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      token: 'auth/token',
      member: 'auth/member',
      users: 'users/users',
      organisation: 'organisations/organisation',
    }),
    emailExist() {
      let user = this.emailOptions.find(
        (element) => element.email == this.form.email
      )
      if (user) {
        return true
      } else return false
    },
  },
  methods: {
    ...mapActions({
      addUser: 'users/addUser',
    }),
    handleOpen() {
      this.dialogFormAdd = true
      this.form.email = this.email
    },
    getEmails(query) {
      if (query) {
        this.loading = true
        this.$store
          .dispatch('users/getUsersByQuery', { params: { q: query } })
          .then((data) => {
            this.filtredUsers = data
            this.loading = false
            this.emailOptions = data
          })
        this.$store
      } else {
        this.emailOptions = []
      }
    },
    Invite(userForm) {
      this.$refs[userForm].validate((valid) => {
        if (valid) {
          let payload = {
            data: {
              ...this.form,
            },
          }
          this.$store.dispatch('users/addUser', payload).then(() => {
            this.dialogFormAdd = false
          })
        } else return false
      })
    },
  },
}
</script>
