<template>
  <el-tooltip
    class="item"
    effect="dark"
    content="Inviter utilisateur"
    placement="top"
  >
    <el-button
      size="small"
      :icon="EditIcon"
      @click="handleEdit(row)"
    ></el-button>
  </el-tooltip>
  <el-dialog
    title="Edition utilisateur "
    v-model="dialogFormEdit"
    append-to-body
    center
  >
    <el-form ref="userForm" :model="form" :rules="rules">
      <el-form-item label="Rôle " :label-width="formLabelWidth" prop="appRole">
        <template #default>
          <el-select
            v-model="form.appRole"
            class="m-2"
            placeholder="Rôle"
            clearable
          >
            <el-option
              v-for="item in filtredRoles"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-form-item>
      <el-form-item
        v-if="isAppUser && isRetailerRole"
        label="Revendeur "
        :label-width="formLabelWidth"
        prop="retailerId"
      >
        <el-select
          v-model="form.retailerId"
          placeholder="Revendeur"
          clearable
          filterable
          :loading="loading"
        >
          <el-option
            v-for="item in retailerOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button
          @click="dialogFormEdit = false"
          type="danger"
          size="default"
          plain
          >Annuler</el-button
        >
        <el-button type="primary" size="default" @click="update('userForm')"
          >Confirmer</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: ['index', 'row'],
  data() {
    return {
      dialogFormEdit: false,
      formLabelWidth: '150px',
      isRetailerRole: false,
      retailerOptions: [],
      form: {
        id: null,
        firstname: null,
        lastname: null,
        phone: null,
        appRole: null,
        retailerId: null,
      },
      confirmPassword: null,
      rules: {
        firstname: [
          {
            required: true,
            message: 'Veuillez saisir le prénom',
            trigger: 'change',
          },
        ],
        lastname: [
          {
            required: true,
            message: 'Veuillez saisir le nom',
            trigger: 'change',
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      users: 'users/users',
      isAppUser: 'auth/isAppUser',
      roleOptions: 'auth/roleOptions',
      retailers: 'retailers/retailers',
      organisation: 'organisations/organisation',
    }),
    filtredRoles() {
      let role = this.user?.appRole ?? null
      let selectedIndex = this.roleOptions.findIndex(
        (item) => item.value == role
      )
      return this.roleOptions.filter((item, index) => index >= selectedIndex)
    },
  },
  watch: {
    'form.appRole': {
      handler(newVal) {
        this.isRetailerRole = ['RETAILER_ADMIN', 'RETAILER_MANAGER'].includes(
          newVal
        )
        this.getRetailers()
      },
    },
  },
  mounted() {},
  methods: {
    ...mapActions({
      addUser: 'users/addUser',
    }),
    update(userForm) {
      this.$refs[userForm].validate((valid) => {
        if (valid) {
          let payload = {
            id: this.form.id,
            data: {
              appRole: this.form.appRole,
              retailerId: this.form.retailerId,
            },
          }
          this.$store.dispatch('users/updateUser', payload).then(() => {
            this.dialogFormEdit = false
          })
        } else return false
      })
    },
    handleEdit(row) {
      this.dialogFormEdit = true
      this.populate(row)
    },
    populate(row) {
      this.form.id = row.id
      this.form.firstname = row.firstname || null
      this.form.lastname = row.lastname || null
      this.form.phone = row.phone || null
      this.form.appRole = row.appRole || null
      this.form.retailerId = row.retailer?.id ?? null
    },
    getRetailers() {
      this.loading = true
      if (this.dialogFormEdit && this.retailers.length > 0) {
        this.retailerOptions = this.retailers
        this.loading = false
        return
      }
      this.$store
        .dispatch('retailers/getRetailers', {})
        .then(() => {
          this.retailerOptions = this.retailers
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
