<template>
  <div style="margin-top: 10px">
    <users-table />
  </div>
</template>

<script>
import UsersTable from './components/UsersTable.vue'
export default {
  components: { UsersTable },
  data() {
    return {}
  },
  computed: {},
  mounted() {
    this.$store.dispatch('users/getUsers', {})
  },
  methods: {},
}
</script>

<style></style>
